<template>
  <v-app-bar color="#003C69" :elevation="4" app>
    <v-app-bar-title class="app-bar-title m-3 text-nowrap">
      <img src="@/assets/images/logo-large-no-background.png" alt="logotype" style="height: 40px;"/>
    </v-app-bar-title>

    <v-card class="m-3 justify-end">
      <v-btn v-if="isAdmin && !mobile" variant="text" to="/admin">User Management</v-btn>
      <v-btn v-if="isAdmin && mobile" variant="text" to="/admin">Management</v-btn>

      <v-btn v-if="!mobile" variant="text" @click="logoutUser()" link>Sign out</v-btn>
      <v-menu v-else>
        <template v-slot:activator="{ props }">
          <v-btn variant="text" icon="mdi-account" v-bind="props"></v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-btn variant="text" @click="logoutUser()" link>Sign out</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card>
  </v-app-bar>
</template>

<script>
import { VAppBar, VAppBarTitle, VCard, VBtn, VMenu, VList, VListItem } from 'vuetify/components'

export default {
  data: () => ({
    isAdmin: false
  }),
  components: {
    VAppBar,
    VAppBarTitle,
    VCard,
    VBtn,
    VMenu,
    VList,
    VListItem
  },
  mounted () {
    this.isAdmin = (this.$store.state.auth.role === 'Owner')
  },
  computed: {
    mobile: function () {
      return this.$vuetify.display.mobile
    }
  },
  methods: {
    logoutUser: function () {
      this.$store.state.auth.cognitoUser.globalSignOut({ AccessToken: this.$store.state.auth.tokens.accessToken }, function (err, data) {
        if (err) console.log(err, err.stack)
      })
      this.$store.commit('signOut')
      this.$router.push('/auth/login')
    }
  }
}
</script>
