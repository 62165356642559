import router from '../../router/index'

const AmazonCognitoIdentity = require('amazon-cognito-identity-js')

const state = {
  isLoading: true,
  AmazonCognitoPoolData: {
    UserPoolId: process.env.VUE_APP_USERPOOL_ID,
    ClientId: process.env.VUE_APP_CLIENT_ID
  },
  userPool: [],
  authDetails: '',
  userData: '',
  cognitoUser: null,
  tokens: {
    accessToken: '',
    idToken: '',
    refreshToken: ''
  },
  username: '',
  errcode: '',
  attributes: [],
  authenticated: false,
  serviceEndpoint: '',
  role: '',
  routeObject: {}
}

const getters = {
  getStateAttributes (state) {
    return state.attributes
  },
  getState (state) {
    return state
  }
}

const mutations = {
  signOut (state) {
    state.cognitoUser.signOut()
    state.sessionToken = null
    state.authenticated = false
    state.username = ''
    state.userPool = []
  },
  setAttributes (state, attributes) {
    state.attributes = attributes
    state.username = state.attributes.filter(function (Obj) {
      return Obj.Name === 'email'
    })[0].Value

    state.serviceEndpoint = state.attributes.filter(function (Obj) {
      return Obj.Name === 'custom:tzUrl'
    })[0].Value

    state.role = state.attributes.filter(function (Obj) {
      return Obj.Name === 'custom:tzRole'
    })[0].Value

    if (state.routeObject &&
      state.routeObject.meta.requireAuth &&
      !state.role.toLowerCase() === 'owner'
    ) {
      router.push('/auth/login')
    }
  },
  setUsername (state, payload) {
    state.username = payload
  },
  signIn (state) {
    state.authenticated = true
  },
  setUserPool (state) {
    state.userPool = new AmazonCognitoIdentity.CognitoUserPool(state.AmazonCognitoPoolData)
  },
  setTokens (state, payload) {
    state.tokens.accessToken = payload.getAccessToken().getJwtToken()
    state.tokens.idToken = payload.getIdToken().getJwtToken()
    state.tokens.refreshToken = payload.getRefreshToken().getToken()
  },
  setCognitoUser (state, payload) {
    state.cognitoUser = payload
  },
  setCognitoDetails (state, authData) {
    state.authDetails = new AmazonCognitoIdentity.AuthenticationDetails(authData)
    state.userData = { Username: authData.Username, Pool: state.userPool }
    state.cognitoUser = new AmazonCognitoIdentity.CognitoUser(state.userData)
  },
  setError (state, payload) {
    state.errcode = payload
  },
  clearError (state) {
    state.errcode = ''
  }
}

const actions = {
  signIn ({ state, commit, dispatch }, authData) {
    commit('clearError')
    commit('setUserPool')
    commit('setCognitoDetails', authData)
    state.cognitoUser.authenticateUser(state.authDetails, {
      onSuccess: (result) => {
        commit('setTokens', result)
        dispatch('getUserAttributes')
        dispatch('setLogoutTimer', 3600)
        router.push('/')
      },
      onFailure: (err) => {
        console.log('Sign-in failed: ' + err.message)
        commit('setError', JSON.stringify(err.code))
      },
      newPasswordRequired: function (userAttributes, requiredAttributes) {
        delete userAttributes.email_verified
        state.attributes = userAttributes
        router.push('/auth/verify-new-password')
      },
      mfaRequired: function (codeDeliveryDetails) {
      }
    })
  },
  globalSignOut ({ state, commit, dispatch }, authData) {
    commit('clearError')
    commit('setUserPool')
    commit('setCognitoDetails', authData)
    state.cognitoUser.authenticateUser(state.authDetails, {
      onSuccess: (result) => {
        state.cognitoUser.globalSignOut({ AccessToken: state.tokens.accessToken }, {
          onFailure: (err) => {
            window.console.log(err, err.stack)
          }
        })
      },
      onFailure: (err) => {
        window.console.log('Sign-in failed for global signout: ' + err.message)
      }
    })
  },

  /**
   * Handle new password
   *
   * @param {string} newPassword
   */
  handleNewPassword ({ state, commit, dispatch }, newPassword) {
    commit('clearError')
    state.cognitoUser.completeNewPasswordChallenge(newPassword, null, {
      onSuccess: (result) => {
        commit('setTokens', result)
        dispatch('getUserAttributes')
        dispatch('setLogoutTimer', 3600)
        router.push('/')
      },
      onFailure: (err) => {
        console.log('Failed setting new password: ' + err.message)
        commit('setError', JSON.stringify(err.code))
      }
    })
  },
  forgottenPassword ({ state, commit, dispatch }, email, verificationCode) {
    commit('clearError')
    commit('setUserPool')
    commit('setCognitoDetails', {
      Username: email
    })

    state.cognitoUser.forgotPassword({
      onSuccess: function (data) {
        // successfully initiated reset password request
        console.log('CodeDeliveryData from forgotPassword: ' + data)
      },
      onFailure: function (err) {
        alert(err.message || JSON.stringify(err))
      },
      inputVerificationCode: function (data) {
        console.log('Code sent to: ' + data)

        /* cognitoUser.confirmPassword(verificationCode, newPassword, {
          onSuccess() {
            console.log('Password confirmed!');
          },
          onFailure(err) {
            console.log('Password not confirmed!');
          }
        }) */
      }
    })
  },
  tryAutoSignIn ({ state, commit, dispatch }, routeNext) {
    state.routeObject = routeNext

    commit('setUserPool')
    const cognitoUser = state.userPool.getCurrentUser()
    if (cognitoUser != null) {
      commit('setCognitoUser', cognitoUser)
      state.cognitoUser.getSession(function (err, session) {
        if (err) {
          router.push('/auth/login')
        } else {
          commit('setTokens', session)
          dispatch('getUserAttributes')
          dispatch('setLogoutTimer', 3600)
        }
      })
    } else {
      router.push('/auth/login')
    }
  },
  getUserAttributes ({ state, commit, dispatch }) {
    state.isLoading = true
    state.cognitoUser.getUserAttributes(function (err, attributes) {
      if (err) {
        router.push('/auth/login')
        state.isLoading = false
      } else if (attributes.some(p => p.Name === 'custom:tzUrl' && p.Value && p.Value.length > 0) &&
                 attributes.some(p => p.Name === 'custom:tzRole' && p.Value && p.Value.length > 0)) {
        commit('setAttributes', attributes)
        commit('signIn')
        state.isLoading = false
      } else {
        router.push('/auth/invalid-login')
        state.isLoading = false
      }
    })
  },
  setLogoutTimer ({ state, commit, dispatch }, expirationTime) {
    setTimeout(() => {
      dispatch('signOut')
    }, expirationTime * 1000)
  },
  signOut ({ commit }) {
    commit('signOut')
    router.push('/auth/login')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
